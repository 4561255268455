import { Typography, Box, makeStyles, List, ListItem } from "@material-ui/core"
import React from "react"
import Dote from "./dote"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  rightImage: {
    top: 0,
    height: "500px",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      height: "250px",
    },
  },
  serviceContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "1440px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      margin: " 0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: " 0",
      flexDirection: "column-reverse",
      position: "initial",
      height: "initial",
    },
  },
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "300px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    padding: "32px",
    flexDirection: "column",
    margin: "32px",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      minHeight: "initial",
      width: "initial",
    },
  },
  line: {
    fontSize: "16px",
    color: "#F4F5F6",
    paddingLeft: "0px",
    textTransform: "uppercase",
  },
}))

export const ServicesMocapTools = ({ tools }) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.serviceContainer}
      id="mocap-services"
      marginBottom={8}
    >
      <Box className={classes.container}>
        <Typography variant="h2" style={{ margin: "16px 0", fontSize: "34px" }}>
          {tools.MocapToolsTitle}
        </Typography>
        <List>
          {tools.MocapToolsList.map(({ Item }) => (
            <ListItem key={Item} className={classes.line}>
              <Dote /> {Item}
            </ListItem>
          ))}
        </List>
      </Box>
      <Img
        fluid={tools.MocapToolsBg.imageFile.childImageSharp.fluid}
        alt="tools"
        className={classes.rightImage}
      />
    </Box>
  )
}
