import { Typography, Box, makeStyles, List, ListItem } from "@material-ui/core"
import React from "react"
import Dote from "./dote"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  rightImage: {
    top: 0,
    position: "absolute",
    height: "800px",
    width: "100%",
    marginBottom: "32px",
    zIndex: "-1",
    objectFit: "cover",
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      padding: "initial",
      marginBottom: "initial",
      position: "initial",
      paddingLeft: "initial",
      height: "initial",
    },
  },

  serviceContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "1440px",
    display: "flex",
    alignItems: "center",
    height: "800px",
    marginTop: "128px",
    [theme.breakpoints.down("md")]: {
      margin: " 0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: " 0",
      flexDirection: "column-reverse",
      position: "initial",
      height: "initial",
    },
  },
  container: {
    position: "absolute !important",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "300px",
    width: "520px",
    padding: "32px",
    flexDirection: "column",
    margin: "32px",
    backgroundColor: "rgba(30, 32, 33, 0.95)",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      minHeight: "initial",
      position: "relative!important",
      width: "100%",
    },
  },
  line: {
    fontSize: "16px",
    color: "#F4F5F6",
    paddingLeft: "0px",
    textTransform: "uppercase",
  },
}))

export const ServicesMocapServices = ({ services }) => {
  const classes = useStyles()
  return (
    <Box
      style={{ justifyContent: "flex-start" }}
      className={classes.serviceContainer}
      id="mocap-services"
    >
      <Box className={classes.container}>
        <Typography variant="h2" style={{ margin: "16px 0", fontSize: "34px" }}>
          {services.MocapServicesTitle}
        </Typography>
        <List>
          {services.MocapServicesList.map(({ Item }) => (
            <ListItem key={Item} className={classes.line}>
              <Dote /> {Item}
            </ListItem>
          ))}
        </List>
      </Box>
      <Img
        className={classes.rightImage}
        fluid={services.MocapServicesBg.imageFile.childImageSharp.fluid}
      />
    </Box>
  )
}
