import React from "react"

function MocapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
    >
      <path
        fill="#F4F5F6"
        fillRule="evenodd"
        d="M64.119 30.308a6.55 6.55 0 01-7.88 6.416c.192.362.323.761.378 1.184l5.525 2.789a3.47 3.47 0 012.09-.696c.377 0 .742.06 1.082.172l3.522-3.258a3.485 3.485 0 112.475 2.554l-3.653 3.38a3.485 3.485 0 11-6.875 1.145l-5.574-2.813a3.47 3.47 0 01-2.575.625l-5.438 7.211c.097.257.165.53.2.811l6.703 3.058a3.485 3.485 0 111.335 6.335l-3.605 5.407a3.485 3.485 0 11-3.166-1.66l4.058-6.088a3.47 3.47 0 01-.162-.788l-6.594-3.008a3.469 3.469 0 01-2.776.572l-3.912 3.99a3.485 3.485 0 01-4.739 4.203l-5.593 4.18a3.485 3.485 0 11-2.168-2.819l5.691-4.254a3.485 3.485 0 014.183-3.712l3.938-4.015a3.485 3.485 0 013.826-4.43l5.468-7.25a3.471 3.471 0 01-.204-1.028l-4.901-2.236a3.494 3.494 0 01-3.16.734l-2.877 3.107a3.485 3.485 0 11-2.401-2.64l2.778-3a3.485 3.485 0 116.786-1.596l5.453 2.488c.451-.274.97-.448 1.526-.492a6.55 6.55 0 1111.236-4.578zm9.136 6.798a1.43 1.43 0 00.294-1.559 1.44 1.44 0 00-1.322-.877 1.435 1.435 0 101.028 2.436zm-13.68-3.823a3.584 3.584 0 001.58-2.975 3.59 3.59 0 00-7.175 0 3.585 3.585 0 003.588 3.588c.743 0 1.434-.226 2.006-.613zM50.29 66.447c0 .46-.217.87-.555 1.133a1.43 1.43 0 01-2.296-.895 1.435 1.435 0 112.85-.238zm6.758-9.65v.001a1.43 1.43 0 00.417-1.012 1.435 1.435 0 10-.417 1.012zm-30.129 9.65a1.431 1.431 0 01-2.45 1.015 1.431 1.431 0 01-.42-1.015 1.435 1.435 0 112.87 0zm7.083-25.067a1.435 1.435 0 112.522.228 1.434 1.434 0 01-2.522-.228zm1.938 18.711a1.435 1.435 0 100-2.87 1.435 1.435 0 000 2.87zm9.43-9.84a1.435 1.435 0 11-2.87 0 1.435 1.435 0 012.87 0zm-1.435-16.605a1.435 1.435 0 11-2.87 0 1.435 1.435 0 012.87 0zm9.225 6.15a1.435 1.435 0 100-2.87 1.435 1.435 0 000 2.87zm12.505 3.69a1.435 1.435 0 11-2.87 0 1.435 1.435 0 012.87 0z"
        clipRule="evenodd"
      ></path>
      <circle
        cx="48"
        cy="48"
        r="45.037"
        stroke="#F26522"
        strokeWidth="4"
      ></circle>
    </svg>
  )
}

export default MocapIcon
