import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import LayoutServices from "../../components/layout/layout-services"
import { ServicesMocap } from "../../components/services/services-mocap"
import { ServicesMocapServices } from "../../components/services/services-mocap-services"
import { ServicesMocapGallery } from "../../components/services/services-mocap-gallery"
import { ServicesMocapTools } from "../../components/services/services-mocap-tools"
import { ServicesMocapTeam } from "../../components/services/services-mocap-team"
import { GetInTouch } from "../../components/services/get-in-touch"

const ServicesMocapPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        servicesMocapPage {
          CreateTitle
          CreateLead
          CreateButton
          CreateBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          HeroTitle
          HeroLead
          HeroButton
          HeroBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          MocapGallery {
            MocapGalleryItem {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          MocapServicesTitle
          MocapServicesList {
            Item
          }
          MocapServicesBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          MocapTeamTitle
          MocapTeamLead
          MocapTeamButton
          MocapTeamBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          MocapToolsTitle
          MocapToolsList {
            Item
          }
          MocapToolsBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <LayoutServices>
      <ServicesMocap hero={data.strapi.servicesMocapPage} />
      <ServicesMocapServices services={data.strapi.servicesMocapPage} />
      <ServicesMocapGallery gallery={data.strapi.servicesMocapPage} />
      <ServicesMocapTools tools={data.strapi.servicesMocapPage} />
      <ServicesMocapTeam team={data.strapi.servicesMocapPage} />
      <GetInTouch create={data.strapi.servicesMocapPage} />
    </LayoutServices>
  )
}

export default ServicesMocapPage
