import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
  },
  container: {
    margin: "128px 0",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "48px 0",
    },
  },
  box: {
    top: "0px",
    left: "0px",
    paddingRight: "16px",
    paddingLeft: "16px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "600px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "50px",
    paddingBotom: "0px",
  },
  bgimage: {
    objectFit: "cover",
    maxHeight: "900px",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "450px",
    },
  },
  contained: {
    textTransform: "uppercase",
    color: "black",
    fontSize: "16px",
    fontWeight: "700",
    border: "solid 3px #F26522",
    background: "#F26522",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      background: "transparent",
      color: "#F26522",
    },
  },
}))

export const ServicesMocapTeam = ({ team }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Img
        className={classes.bgimage}
        fluid={team.MocapTeamBg.imageFile.childImageSharp.fluid}
      />
      <Box className={classes.box}>
        <Box className={classes.content}>
          <Typography variant="h2" className={classes.title}>
            {team.MocapTeamTitle}
          </Typography>
          <Typography
            style={{ textAlign: "center", margin: "20px 0" }}
            variant="body1"
          >
            {team.MocapTeamLead}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
