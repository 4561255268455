import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"
import ScrollTo from "../utils/scroll-to"
import Down from "./down"
import MocapIcon from "./mocap-icon"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    position: "relative",
    height: "90vh",
    [theme.breakpoints.down("sm")]: {},
  },
  content: {
    maxWidth: "800px",
    textAlign: "center",
    margin: "20px 0",
    color: "#fff",
    filter: "drop-shadow(4px 4px 8px #000b)",
  },
  button: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#F26522",
    fontSize: "16px",
    marginTop: "48px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
    fontWeight: "700",
    cursor: "pointer",
  },
}))

export const ServicesMocap = ({ hero }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box>
        <Img
          style={{
            height: "90vh",
            objectFit: "cover",
            marginBottom: "50px",
            filter: "brightness(0.4)",
          }}
          fluid={hero.HeroBg.imageFile.childImageSharp.fluid}
        />
        <Box
          position="absolute"
          my={5}
          px={2}
          top={0}
          left={0}
          height="100%"
          width="100%"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxWidth="820px"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              style={{
                marginBottom: "20px",
                filter: "drop-shadow(0px 0px 4px #0004)",
              }}
            >
              <MocapIcon />
            </Box>
            <Typography
              variant="h1"
              align="center"
              style={{
                text: "uppercase",
                filter: "drop-shadow(0px 0px 4px #000b)",
              }}
            >
              {hero.HeroTitle}
            </Typography>
            <Typography className={classes.content} variant="h3">
              {hero.HeroLead}
            </Typography>
            <ScrollTo
              className={classes.button}
              offset={-140}
              to="mocap-services"
            >
              {hero.HeroButton} <Down />
            </ScrollTo>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
